export function formatNumber(number: number) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
}

export const inchesToCentimeters = (inches: number) =>
  Math.round(inches * 2.54 * 100) / 100
