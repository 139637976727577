import React, { useEffect, useMemo, useState } from "react"
import Masonry from "react-masonry-css"
import AuctionCard from "../AuctionCard/AuctionCard"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

import * as styles from "./MasonryGrid.module.scss"

interface IMasonryGrid {
  data: any
  showSort: boolean
  homepageDisplay?: boolean
  col: number
}

const MasonryGrid = ({
  data,
  showSort,
  homepageDisplay = false,
  col,
}: IMasonryGrid) => {
  const [lots, setLots] = useState([])
  const [selectedOption, setSelectedOption] = useState("Default")
  const [reserveMet, setReserveMet] = useState([])
  const [reserveNotMet, setReserveNotMet] = useState([])

  const notEndedAuctions = useMemo(() => {
    return data.filter((auction: any) => auction.ended !== true)
  }, [data])

  const endedAuctions = useMemo(() => {
    return data.filter((auction: any) => auction.ended === true)
  }, [data])

  useEffect(() => {
    if (selectedOption === "Default") {
      setLots(data.filter((auction: any) => auction.ended !== true))
    } else if (selectedOption === "Ending Soonest") {
      let copiedArr = [...lots]
      copiedArr.sort((a, b) => a?.auction?.dateEnding - b?.auction?.dateEnding)

      setLots(copiedArr)
    } else if (selectedOption === "Price high to low") {
      let copiedArr = [...lots]
      copiedArr.sort(
        (a, b) => b?.auction?.currentBidInETH - a?.auction?.currentBidInETH
      )

      setLots(copiedArr)
    } else if (selectedOption === "Price low to high") {
      let copiedArr = [...lots]
      copiedArr.sort(
        (a, b) => a?.auction?.currentBidInETH - b?.auction?.currentBidInETH
      )

      setLots(copiedArr)
    } else if (selectedOption === "Reserve Met") {
      setReserveMet(
        notEndedAuctions.filter(auction => {
          if (
            auction?.auction?.highestBid?.amountInEth >=
            auction?.auction?.reservePriceInETH
          ) {
            return auction
          }
        })
      )

      setReserveNotMet(
        notEndedAuctions.filter(
          auction =>
            auction?.auction?.highestBid?.amountInEth <
              auction?.auction?.reservePriceInETH ||
            auction?.auction?.highestBid === null
        )
      )
    }
  }, [selectedOption, data, notEndedAuctions])

  const breakpointColumnsObj = useMemo(() => {
    if (col === 3) {
      return {
        default: 3,
        768: 2,
      }
    } else if (col === 4) {
      return {
        default: 4,
        1024: 3,
        768: 2,
      }
    }
  }, [])

  const options = [
    "Default",
    "Ending Soonest",
    "Price low to high",
    "Price high to low",
    "Reserve Met",
  ]

  return (
    <>
      {showSort === true && (
        <div className={styles.masonryGridDropdown}>
          <h3>Artworks</h3>
          <div className={styles.masonryGridDropdownInner}>
            <span>SORT: </span>
            <Dropdown
              options={options}
              onChange={value => setSelectedOption(value.value)}
              value={selectedOption}
              controlClassName={styles.dropdownControl}
              className={styles.dropdown}
            />
          </div>
        </div>
      )}

      {selectedOption !== "Reserve Met" ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className={styles.myMasonryGrid}
          columnClassName={styles.myMasonryGrid_column}
        >
          {homepageDisplay
            ? data.map((obj, i) => (
                <AuctionCard key={i} data={obj?.node ? obj?.node : obj} />
              ))
            : lots.map((obj, i) => (
                <AuctionCard key={i} data={obj?.node ? obj?.node : obj} />
              ))}
        </Masonry>
      ) : (
        <>
          <h3 className={styles.masonryGridTitle}>Reserve Met</h3>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGrid_column}
          >
            {reserveMet.map((obj, i) => (
              <AuctionCard key={i} data={obj?.node ? obj?.node : obj} />
            ))}
          </Masonry>

          <h3 className={styles.masonryGridTitle}>Reserve Not Met</h3>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGrid_column}
          >
            {reserveNotMet.map((obj, i) => (
              <AuctionCard key={i} data={obj?.node ? obj?.node : obj} />
            ))}
          </Masonry>
        </>
      )}

      {endedAuctions?.length > 0 && !homepageDisplay && (
        <>
          <h3 className={styles.masonryGridTitle}>Closed</h3>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGrid_column}
          >
            {endedAuctions.map((obj, i) => (
              <AuctionCard key={i} data={obj?.node ? obj?.node : obj} />
            ))}
          </Masonry>
        </>
      )}
    </>
  )
}

export default MasonryGrid
