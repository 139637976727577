import { Link } from "gatsby"
import React, { useMemo } from "react"
import { formatNumber } from "../../utils/number"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fromUnixTime } from "date-fns"
import Countdown from "../Countdown/Countdown"

import * as styles from "./AuctionCard.module.scss"

const AuctionCard = ({ data }: any) => {
  const image = useMemo(() => {
    if (data?.asset?.mainMedia) {
      if (data.asset?.mainMedia?.file?.contentType?.includes("video")) {
        return {
          type: "video",
          image:
            data?.asset?.animationPreviewMedia?.file?.url ||
            data?.asset?.mainMedia?.videoH264?.path,
          title: data?.asset?.mainMedia?.title,
        }
      } else {
        return {
          type: "image",
          image: getImage(data?.asset?.mainMedia),
          title: data?.asset?.mainMedia?.title,
        }
      }
    } else {
      if (data?.additionalMedia?.[0]?.file?.contentType?.includes("video")) {
        return {
          type: "video",
          image: data.additionalMedia?.[0]?.file?.url,
          title: data.additionalMedia?.[0]?.title,
        }
      } else {
        return {
          type: "image",
          image: getImage(data?.additionalMedia?.[0]),
          title: data?.additionalMedia?.[0]?.title,
        }
      }
    }
  }, [data?.additionalMedia, data?.asset?.mainMedia])

  const priceLabel = useMemo(() => {
    if (data?.auction) {
      const price = `${formatNumber(data?.auction?.currentBidInETH)} ETH`

      const bids =
        data?.auction?.bids?.length === 1
          ? "(1 Bid)"
          : `(${data?.auction?.bids?.length} Bids)`

      return `${price} ${data?.auction?.bids?.length ? bids : ""}`
    }
    return ""
  }, [data.auction])

  const ending = useMemo(() => {
    return data.auction && data.auction.dateEnding
      ? fromUnixTime(data.auction.dateEnding)
      : null
  }, [data.auction])

  const visual = useMemo(() => {
    return image?.type === "video" ? (
      <video
        src={image?.image}
        width="100%"
        playsInline
        autoPlay
        muted
        loop
      ></video>
    ) : (
      <GatsbyImage
        image={image?.image}
        alt={image?.title ? image.title : ""}
        className={styles.cardImg}
      />
    )
  }, [image.image, image.type, image.title])

  return (
    <div className={styles.card}>
      <Link
        to={`/artwork/${data.asset?.artist?.slug || "unknown"}/${
          data?.asset?.slug || "untitled"
        }/${data.lotId}`}
        className={styles.cardLink}
      >
        <div>{visual}</div>
        <ul className={styles.cardList}>
          <li>{data?.asset?.artist?.name}</li>
          <li>
            <i>
              {data?.asset?.title}
              {data?.asset?.yearOfWork && `, ${data?.asset?.yearOfWork}`}
            </i>
          </li>
          <li>Lot ID: {data?.lotId}</li>
          {data?.ended === true ? (
            <li>Closed</li>
          ) : (
            <>
              <li>{priceLabel}</li>
              <li>
                {data?.auction ? (
                  data?.auction?.hasEnded ? (
                    "Ended"
                  ) : (
                    <Countdown deadline={ending} classname="" />
                  )
                ) : null}
              </li>
            </>
          )}
        </ul>
      </Link>
    </div>
  )
}

export default AuctionCard
